import React, { useEffect, useState, useContext } from "react"
import { Container, Section } from "../grid/grid"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import Modal from "react-modal"
import OtpInput from "react-otp-input"
import { navigate } from "gatsby"
import { LangContext } from "../../../context/LangContext"
import { submitUtm, extractCookie } from "../../../utils/handle-utm"

const importNumberForm = () => import("react-number-format")

const GET_OTP = gql`
  mutation getOtp($phoneNumber: String, $lang: String) {
    requestOtp(
      input: { clientMutationId: "", phoneNumber: $phoneNumber, lang: $lang }
    ) {
      status
      message
    }
  }
`

const SUBMIT_OTP = gql`
  mutation SubmitFormInfo(
    $fullName: String
    $email: String
    $phoneNumber: String
    $otpCode: String
    $propertyName: String
    $utm: String
  ) {
    submit2FormGetMoreInfo(
      input: {
        clientMutationId: ""
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        otpCode: $otpCode
        propertyName: $propertyName
        utm: $utm
      }
    ) {
      status
      message
    }
  }
`

export const LeadForm = ({
  name,
  projectCode,
  clusterCode,
  leadSourceName,
  projectData,
  propertyTypesProject,
  contentIds,
  eCatalogProject,
  thisUrleCatalog,
}) => {
  const { lang, setLang } = useContext(LangContext)
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  })

  const [showModal, setShowModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [otp, setOtp] = useState()
  const [otpError, setOtpError] = useState(false)
  const [timeLimit, setTimeLimit] = useState(180)
  const [NumberFormat, setNumberFormat] = useState()
  const [btn, setBtn] = useState(false)

  const [getOtp, { loading: getOtpLoading }] = useMutation(GET_OTP)
  const [submitOtp, { loading: submitOtpLoading }] = useMutation(SUBMIT_OTP)

  useEffect(() => {
    importNumberForm().then(component => setNumberFormat(component))
  }, [])

  useEffect(() => {
    let timer = setTimeout(() => {
      if (timeLimit >= 0 && showModal) {
        setTimeLimit(timeLimit - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [timeLimit, showModal])

  const NFComponent = NumberFormat && NumberFormat.default

  const formatTimeLeft = time => {
    let minutes = Math.floor(time / 60)
    let seconds = time % 60
    if (seconds < 10 && seconds >= 0) {
      seconds = `0${seconds}`
    } else if (seconds < 0) {
      seconds = "00"
    }

    if (minutes < 10 && minutes >= 0) {
      minutes = `0${minutes}`
    } else if (minutes < 0) {
      minutes = "00"
    }
    return `${minutes}:${seconds}`
  }

  const changeNullToBeZero = val => {
    if (val == null) return 0
    return val
  }

  const setDataLayerValue = event => {
    const value = {
      event: event,
      page_url: window.location.href,
      page_title: name,
      content_type: "home_listing",
      content_ids: contentIds,
      city: "bsd-city",
      region: "bsd-city",
      country: "Indonesia",
      lease_start_date: "2021-01-01",
      lease_end_date: "2100-01-01",
      currency: "IDR",
      preferred_price_range: [
        projectData?.projectCustomfields?.preferredPriceRangeFrom,
        projectData?.projectCustomfields?.preferredPriceRangeTo,
      ],
      preferred_baths_range: [
        changeNullToBeZero(
          projectData?.projectCustomfields?.preferredBathsRangeFrom
        ),
        changeNullToBeZero(
          projectData?.projectCustomfields?.preferredBathsRangeTo
        ),
      ],
      preferred_bed_range: [
        changeNullToBeZero(
          projectData?.projectCustomfields?.preferredBedRangeFrom
        ),
        changeNullToBeZero(
          projectData?.projectCustomfields?.preferredBedRangeTo
        ),
      ],
      property_type: propertyTypesProject,
    }
    return value
  }

  const setDetailDevelopmentDataLayer = value => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(value)
  }

  function validateEmail(value) {
    let error
    if (!value) {
      error = "Required"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address"
    }
    return error
  }

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)

    return (
      <Form>
        <div className="form-group">
          <Field
            name="name"
            className="form-control"
            placeholder={
              lang == "EN" ? "Enter your name" : "Masukkan nama kamu"
            }
            type="text"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="form-group">
          <Field
            name="email"
            className="form-control"
            placeholder={
              lang == "EN" ? "Enter your email" : "Masukkan email kamu"
            }
            type="text"
            validate={validateEmail}
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        {NFComponent && (
          <div className="form-group">
            <NFComponent
              name="phoneNumber"
              className="form-control"
              placeholder={
                lang == "EN"
                  ? "Enter your phone number"
                  : "Masukkan nomor telepon"
              }
              allowEmptyFormatting
              prefix="+62"
              value={values.phoneNumber}
              onValueChange={val =>
                phoneNumberHandler(val.formattedValue, setFieldValue)
              }
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <div className="invalid-value d-block">{errors.phoneNumber}</div>
            )}
          </div>
        )}
        <div className="form-group">
          <button className="btn btn-secondary btn-block">
            {btnLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <>
                {lang == "EN" ? "Submit" : "Kirim"}{" "}
                <i className="fas fa-arrow-right ml-1"></i>{" "}
              </>
            )}
          </button>
          <p className="pt-3 text-muted">
            {lang == "EN"
              ? "BSD City will never sell or share your information with a third party"
              : "BSD City tidak akan pernah menjual atau membagikan informasi Anda kepada pihak ketiga"}
          </p>
        </div>
      </Form>
    )
  }

  const handleOtp = otp => {
    setOtp(otp)
  }

  const getOtpHandler = () => {
    getOtp({
      variables: {
        phoneNumber: formValues.phoneNumber.split("+").join(""),
        lang: lang,
      },
    }).then(res => {
      if (res.data.requestOtp.status === "success") {
        setTimeLimit(180)
        setShowModal(true)
      }
    })
  }

  const sendOtp = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitOtp({
      variables: {
        fullName: formValues.name,
        email: formValues.email,
        phoneNumber: formatNumber,
        otpCode: otp,
        propertyName: name,
        utm: extractCookie("utm_params=")
          ? JSON.stringify(extractCookie("utm_params="))
          : null,
      },
    }).then(res => {
      if (res.data.submit2FormGetMoreInfo.status === "success") {
        setDetailDevelopmentDataLayer(setDataLayerValue("InitiateCheckout"))
        setBtn(true)
        setTimeout(() => {
          setShowModal(false)
          // setSuccessModal(true)
          setTimeLimit(180)
          setFormValues({
            name: "",
            email: "",
            phoneNumber: "",
          })
          setOtp("")
          navigate(`/thankyou`)
        }, 1500)

        if (otpError) {
          setOtpError(false)
        }
        // send data to CPI
        if (true) {
          submitUtm({
            fullname: formValues.name,
            email: formValues.email,
            mobile: formValues.phoneNumber,
            project_name: name,
            projects_code: projectCode,
            cluster_code: clusterCode,
            lead_source_name: leadSourceName,
            web: "bsdcity.com",
          })
        }
      } else {
        setOtpError(true)
      }
    })
  }

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(
        `${lang == "EN" ? "Name is required" : "Nama harus di isi"}`
      ),
      email: Yup.string().required(
        `${lang == "EN" ? "Email is required" : "Email harus di isi"}`
      ),
      phoneNumber: Yup.string().required(
        `${
          lang == "EN"
            ? "Phone number is required"
            : "Nomor telepon harus di isi"
        }`
      ),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatNumber = values.phoneNumber.split("+").join("")
      getOtp({ variables: { phoneNumber: formatNumber, lang: lang } }).then(
        res => {
          if (res.data.requestOtp.status === "success") {
            setShowModal(true)
          }
        }
      )
    },
  })(LeadForm)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const handleModal = () => {
    setShowModal(!showModal)
  }
  const handleSuccessModal = () => {
    if (thisUrleCatalog !== "#") {
      window.open(thisUrleCatalog, "_blank")
    } else {
      window.location.reload()
    }
    setSuccessModal(false)
    setBtn(false)
    setDetailDevelopmentDataLayer(setDataLayerValue("Purchase"))
  }
  return (
    <>
      <Section className="sc-lead-form bg-light">
        <Container>
          <div className="row">
            <div className="col-md-6">
              <h2>
                {lang == "EN"
                  ? "Get More Information"
                  : "Dapatkan Informasi Lebih Lanjut"}
              </h2>
              <p>
                {lang == "EN"
                  ? "Visit our Marketing Gallery to view interactive models and get more information about this property."
                  : "Kunjungi Galeri Pemasaran kami untuk melihat model interaktif dan dapatkan informasi lebih lanjut tentang properti ini."}
              </p>
            </div>
            <div className="col-md-6">
              {NFComponent && <EnhancedLeadForm btnLoading={getOtpLoading} />}
            </div>
          </div>
        </Container>
      </Section>
      <Modal
        isOpen={showModal}
        onRequestClose={handleModal}
        style={customStyles}
        contentLabel="campaign modal"
      >
        <div className="modal-otp modal-body">
          <div className="container">
            <div className="modal-content pl-0 pr-0">
              <h2>OTP</h2>
              <p>
                {lang == "EN"
                  ? "To proceed, please enter the 6-digits OTP code sent to your mobile number"
                  : "Untuk melanjutkan, masukkan 6 digit kode OTP yang dikirimkan ke Anda nomor telepon anda"}{" "}
                <span className="font-weight-bold">
                  {" "}
                  {formValues.phoneNumber}{" "}
                </span>
              </p>
              <OtpInput
                value={otp}
                containerStyle="otp-container mb-3"
                inputStyle={`otp-input ${otpError ? "error" : ""}`}
                onChange={handleOtp}
                numInputs={4}
                isInputNum={true}
              />
              <div className="modal-text">
                {otpError && (
                  <p className="otp-error text-danger text-center">
                    {lang == "EN"
                      ? "The code you entered does not match, please re-enter."
                      : "Kode yang Anda masukkan tidak cocok, silakan masukkan kembali."}
                  </p>
                )}
                {getOtpLoading ? (
                  <p className="mt-2 text-primary text-center">
                    <i className="fa fa-spinner fa-spin"></i>
                  </p>
                ) : timeLimit > 0 ? (
                  <p className="text-muted text-center font-weight-bold">
                    {lang == "EN"
                      ? "Haven’t received OTP code?"
                      : "Belum menerima kode OTP?"}{" "}
                    <span className="font-weight-extrabold">
                      {lang == "EN" ? "Resend" : "Kirim Ulang"}
                    </span>{" "}
                    ({timeLimit})
                  </p>
                ) : (
                  <p
                    className="text-blue text-center font-weight-bold pointer"
                    onClick={getOtpHandler}
                  >
                    {lang == "EN"
                      ? "Haven’t received OTP code?"
                      : "Belum menerima kode OTP?"}{" "}
                    <span className="font-weight-extrabold">
                      {lang == "EN" ? "Resend" : "Kirim Ulang"}
                    </span>
                  </p>
                )}
              </div>
              <button
                className={`btn btn-secondary btn-block ${btn && "disabled"}`}
                onClick={sendOtp}
                disabled={
                  (submitOtpLoading && "disabled") || (btn && "disabled")
                }
              >
                {submitOtpLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <>
                    {" "}
                    {lang == "EN" ? "Submit" : "Kirim"}{" "}
                    <i className="fas fa-arrow-right ml-1"></i>{" "}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={successModal}
        onRequestClose={handleSuccessModal}
        style={customStyles}
        contentLabel="campaign modal"
      >
        <div className="modal-otp modal-body text-center">
          <div className="container">
            <div className="modal-content pl-0 pr-0">
              {/* <img alt="success" src={icSuccess} className="mw-25 mb-4" /> */}
              <h2>{lang == "EN" ? "OTP Verified!" : "Otp Terverifikasi"}</h2>
              <p>
                {lang == "EN"
                  ? "Your data has been submitted. Our BSD CITY team will reach out to you."
                  : "Data Anda telah dikirim. Tim BSD CITY akan menghubungi Anda."}
              </p>
              <button
                className="btn btn-secondary"
                onClick={handleSuccessModal}
              >
                {lang == "EN" ? "Visit e-Catalog" : "Kunjungi e-Catalog"}{" "}
                <i className="fas fa-arrow-right ml-1"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
